import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as R from "ramda";

import {
  Wrapper,
  ButtonsWrapper,
  Button,
} from "scenes/Games/Show/PostMatchStatistic/ui";
import Logo from "assets/images/pokerithm.svg";
import AvatarIcon from "assets/images/AvatarIcon.png";

import Content, { TABS } from "./Content";
import LargeButton from "components/LargeButton";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Coin from "assets/images/BeetcoinIcon.png";
import * as breakpoints from "constants/sizes";
import { getNumberWithOrdinal } from "./Content/StatisticsTable";
import { mock } from "./Content/mock";
import { useParams } from "react-router-dom";

const ExitButton = styled(LargeButton)`
  width: min-content;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 !important;
`;

const Container = styled.div`
  background-color: #aee0f7d9;
  padding: 4px;
  @media all and (min-width: 1200px) {
    padding: 16px;
  }
  border-radius: 16px;
  margin: 0 !important;
`;

const Title = styled.div`
  line-height: 1;
  font-size: 26px;
  font-family: ContraxHV;
  font-weight: bold;
  color: #1e2e37;
  align-self: center;
`;

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding: 0 16px;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    margin-bottom: 16px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row-reverse;
    gap: 12px;
  }
`;

const NickName = styled.div`
  margin-right: 16px;
  color: #1e2e37;
  font-size: 36px;
  font-family: ContraxHV;
`;

const FooterLine = styled.div`
  display: flex;
  align-items: end;
  justify-content: start;
  margin-top: 16px;
  margin-right: 15px !important;
  flex-direction: column;

  p {
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 5px !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    justify-content: start;
    align-items: end;
  }
`;

const Position = styled.div`
  margin-left: 20px;
  font-family: Play;
  font-size: 28px;
  font-weight: bold;

  @media all and (min-width: ${breakpoints.desktop}) {
    margin-left: 30px;
    font-size: 36px;
  }
`;

const Polygon = styled.div`
  margin-left: 18px;
  border: 10px solid transparent;
  border-left: 15px solid #fff;
`;

const currentUserQ = gql`
  query currentUser {
    currentUser {
      id
      beats
      profileImage
      lastName
      firstName
      username
      matchPlayStatistics {
        rank
        matchPointsRate
      }
    }
  }
`;

const AdaptiveLogo = styled(Logo)`
  height: 60px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 30px;
  }
`;

const Avatar = styled.img`
  height: 45px;
  border-radius: 50%;
`;
// use this data for mocking
// const postMatchStatistic = mock
class PostMatchStatistic extends React.Component {
  state = {
    activeTab: R.head(TABS),
  };

  render() {
    let { postMatchStatistic, closeModal, logs, currentUser } = this.props;

    //if no data is passed, we can assume we need to show the mock

    // const matchId = window.location.href.split("/")[4];

    // if (!postMatchStatistic) {
    //   const baseUrl = process.env.REACT_APP_GRAPHQL_URI.replace(
    //     "graphql",
    //     "api"
    //   );

    //   console.log(this.props);
    //   const res = fetch(`${baseUrl}/matches/${matchId}`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((res) => {
    //       // postMatchStatistic = mock;
    //       console.log(postMatchStatistic);
    //     });
    // }

    const { activeTab } = this.state;
    // //TODO: GET FROM BACK-END
    // const position = getNumberWithOrdinal(
    //   R.path(["match_results", "msr", "placement", "value"], postMatchStatistic)
    // );
    // const reward = "23";

    return (
      <Wrapper>
        <TopLine>
          <Link to="/dashboard">
            <AdaptiveLogo />
          </Link>
          <Query query={currentUserQ}>
            {({ data }) => {
              return (
                <React.Fragment>
                  <UserInfo>
                    <NickName>
                      {R.path(["currentUser", "username"], data)}
                    </NickName>
                    <Avatar
                      src={
                        R.path(["currentUser", "profileImage"], data) ||
                        AvatarIcon
                      }
                      alt="avatar"
                    />
                  </UserInfo>
                </React.Fragment>
              );
            }}
          </Query>
        </TopLine>
        <Container>
          <div
            style={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItem: "center",
            }}
            className="switcher"
          >
            <Title>Match Results</Title>
            
            <ButtonsWrapper>
              <Button
              active={true}
              onClick={() => {
                window.open("https://pokerithm.com/rules", "_blank");
              }}
                >
                  Ranking Guide
            </Button>
            </ButtonsWrapper>
            
            <ButtonsWrapper>
              {TABS.map((tab) => (
                <Button
                  key={tab.key}
                  active={activeTab.key === tab.key}
                  onClick={() => this.setState({ activeTab: tab })}
                >
                  {tab.title}
                </Button>
              ))}
            </ButtonsWrapper>
          </div>

          <Content
            activeTab={activeTab}
            postMatchStatistic={postMatchStatistic}
            closeModal={closeModal}
            logs={logs}
            currentUser={currentUser}
          />
        </Container>

        <FooterLine>
          {/* <Position>{position} Place</Position> */}
          <ExitButton size="sm" bg="pink" margin="0" onClick={closeModal}>
            Exit
          </ExitButton>
        </FooterLine>
      </Wrapper>
    );
  }
}

export default PostMatchStatistic;
